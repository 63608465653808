import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact"

const Body = () => {
  return (
    <>
      <About />
      <Projects />
      <Contact />
    </>
  );
};

export default Body;
